body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Prevents horizontal scroll caused by padding/margin */
}

.timeline-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap; /* Prevents wrapping of timeline items */
  padding: 20px 10px; /* Keep padding uniform */
  background-color: #f5f5f5;
  margin: 0;
  width: 100vw; /* Ensures the container takes full viewport width */
  background: linear-gradient(to right, #e0eafc, #cfdef3);
  box-sizing: border-box; /* Makes sure borders and paddings are included in the element's total width and height */
}

.center-content {
  justify-content: center;
}

.timeline-item {
  flex: 0 0 auto; /* Do not grow, do not shrink, but can be basis auto */
  margin-right: 20px; /* Space between items */
  min-width: 250px; /* Adjusted minimum width */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.timeline-item:last-child {
  margin-right: 0; /* Remove margin for the last item */
}

.timeline-item:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}


.timeline-content h3 {
  margin: 0 0 10px 0; /* Space between title and description */
}

/* Optional: Styles for the scrollbar */
.timeline-container::-webkit-scrollbar {
  height: 8px;
}

.timeline-container::-webkit-scrollbar-thumb {
  background-color: #09403f;
  border-radius: 4px;
}

.timeline-container::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.title-with-icon {
  display: flex;
  align-items: center; /* This ensures vertical alignment is centered */
  justify-content: space-between; /* This spreads the title and icon apart */
}

.timeline-content h3 {
  margin: 0; /* Adjust as needed */
  flex-grow: 1; /* Ensures the title takes up the available space */
}

.timeline-icon {
  flex-shrink: 0; /* Prevents the icon from shrinking */
  margin-left: 10px; /* Adds space between the title and the icon */
}

.titles {
  flex-grow: 1; /* Allows the title container to take up available space pushing the icon to the edge */
  display: flex;
  flex-direction: column; /* Stacks h3 and h4 on top of each other */
}

.timeline-icon img {
  width: 100%; /* Adjust the width as necessary */
  height: auto; /* Maintains the aspect ratio */
  max-width: 50px; /* Maximum width to ensure icons are not too large */
  max-height: 50px; /* Maximum height to keep the icons from being too tall */
  object-fit: contain; /* Ensures the image is scaled correctly within its box */
}

@media (max-width: 768px) {
  .timeline-icon img {
    max-width: 40px; /* Slightly smaller icons on mobile */
    max-height: 40px;
  }
}

.color-key-container {
  display: flex;
  padding: 10px;
  justify-content: center; /* Center align the keys */
  flex-wrap: wrap; /* Wrap onto the next line if space is limited */
}

.color-key {
  display: flex;
  align-items: center; /* Vertical alignment */
  margin-right: 20px; /* Space between keys */
}

.color-box {
  width: 20px;
  height: 20px;
  border-radius: 50%; /* Circular shape */
  margin-right: 5px; /* Space between box and text */
}

.contact-info-section {
  text-align: center;
  padding: 40px 20px;
}

.contact-method {
  display: flex;
  flex-direction: column; /* Stacks icon and text vertically */
  align-items: center; /* Centers items vertically within each 'contact-method' */
  text-decoration: none; /* Removes underline from links */
  color: inherit; /* Inherits color from parent for consistency */
}

.contact-method, .contact-method a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none; /* Remove underline from links */
  color: inherit; /* Inherit text color */
}

.contact-icon {
  /* Apply brand colors as mentioned in previous message */
  margin-bottom: 10px; /* Adds space between icon and text */
  width: 40px; /* Or size as preferred */
  height: 40px; /* Maintain aspect ratio */
}

.contact-detail {
  transition: transform 0.3s ease;
}

.contact-method:hover .contact-detail {
  transform: translateY(-5px); /* Add a slight lift effect on hover */
}

.lifestyle-interests-section {
  text-align: center;
  padding: 40px 20px;
  background-color: #f5f5f5;
}

.interests-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.interest-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Ensures the border-radius clips the content */
}

.interest-image {
  width: 100%;
  height: 200px; /* Adjust height as needed */
  object-fit: cover; /* Ensures images cover the area nicely */
}

.interest-card h3 {
  margin-top: 15px;
}

.interest-card p {
  padding: 0 15px 15px;
}

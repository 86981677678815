@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

.about-section {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  padding: 40px 20px;
  background-color: #222; /* Darker background for the whole section */
  color: #f0f0f0; /* Light text for readability */
}

.highlight-banner {
  margin: 20px 0;
  padding: 15px;
  background-color: #f0f8ff; /* Example: Light blue background for a soft, attention-grabbing look */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #333; /* Darker text for contrast */
  font-family: 'Arial', sans-serif; /* Example: Choose a font that fits your site's theme */
}

.highlight-banner p {
  margin: 0; /* Adjust spacing around the paragraph if needed */
  font-size: 1.1rem; /* Slightly larger font size for emphasis */
  line-height: 1.5; /* Improve readability */
}

.banner-link {
  color: #007bff; /* Or any color that stands out, matching your site's theme */
  text-decoration: underline; /* Makes it clear it's clickable */
  font-weight: bold; /* Make the link bold for additional emphasis */
}

/* Consider adding hover effects to the link for interactivity */
.banner-link:hover {
  color: #0056b3; /* A darker shade of blue when hovered */
  text-decoration: none; /* Optional: change or remove the underline on hover */
}

.profile-img {
  max-width: 250px; /* Adjust size as needed */
  border-radius: 50%; /* Circle profile picture */
  margin-bottom: 20px;
}

.download-cv-btn {
  background-color: #007bff; /* Primary button color */
  color: #ffffff;
  padding: 10px 20px;
  margin: 20px 0; /* Space around the button */
  border-radius: 20px; /* Rounded corners for the button */
  transition: background-color 0.3s ease;
}

.download-cv-btn:hover {
  background-color: #0056b3;
}

.about-description p {
  font-family: 'Lato', sans-serif;
  margin-bottom: 15px; /* Adds space between paragraphs for clear separation */
  line-height: 1.6; /* Improves readability */
  text-align: center; /* Aligns text to the left for a more traditional reading flow */
}

.about-description p b {
  font-weight: bold; /* Ensures the graduation date stands out */
}

.about-description a {
  color: #007bff; /* Highlights the link in a different color */
  text-decoration: underline; /* Ensures it's recognized as clickable */
}

.about-description a:hover {
  color: #0056b3; /* Darker shade on hover for interactive feedback */
}

.skills-container {
  margin-top: 20px;
}

.skills-container h3 {
  margin-bottom: 10px;
}

.skills {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.skills li {
  background-color: #333; /* Darker background for each skill */
  color: #f0f0f0; /* Lighter text color for contrast */
  padding: 8px 16px;
  border-radius: 20px;
  margin: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}


.skills li:hover {
  background-color: #555; /* Slightly lighter than the base color for hover */
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.3); /* Enhanced shadow for a "lifted" effect */
}


a {
  color: #007bff;
  text-decoration: none; /* Removes underline from links */
}

a:hover {
  text-decoration: underline; /* Adds underline on hover for clarity */
}

.skills-container {
  margin-top: 20px;
}

.skills-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skill-card {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px; /* Adjust based on content */
  padding: 10px;
  margin: 10px;
  text-align: center;
  background-color: #f0f0f0; /* Subtle background */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Soft shadow for depth */
}

.skill-icon {
  width: 60px; /* Adjust based on your icon size */
  height: 60px;
  margin-bottom: 10px;
}

.publications-section {
  padding: 40px 0;
}

.section-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2rem;
}

.publications-list {
  list-style: none;
  padding: 0;
}

.publication {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.publication-title {
  margin: 0 0 10px;
}

.publication-abstract {
  margin-bottom: 10px;
}

.btn {
  display: inline-block;
  margin: 5px;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.btn:hover, .btn:focus {
  background-color: #218838; /* Darker shade for the hover state */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Larger shadow for hover */
  transform: translateY(-2px); /* Slightly raises the button */
}

.abstract-btn {
  background-color: #6f96c9; /* Different color for abstract buttons */
}

.abstract-btn:hover, .abstract-btn:focus {
  background-color: #138496;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

.read-more-btn {
  background-color: #6f96c9; /* Green color to signify action */
  color: white; /* Keep text color consistent for readability */
}

.read-more-btn:hover, .read-more-btn:focus {
  background-color: #218838; /* A darker shade for hover effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}
.btn + .btn {
  margin-left: 10px;
}
